/* eslint-disable react/prop-types */

import React from "react";
import { Link } from "gatsby";
import AndTomorrow from "~components/svg/AndTomorrow";

const Footer = ({ clipped }) => (
  <footer
    className={`footer ${
      clipped ? `pointer-events-auto text-slate` : `text-off-white`
    } w-full fixed bottom-0 right-0 left-0 pb-4 sm:pb-2`}
  >
    <div className="grid">
      <div className="grid-end-12 flex items-end justify-between">
        <ul className="footer__links flex flex-col self-stretch justify-end">
          <li className="footer__link transition-opacity f2 uppercase">
            <Link to="/" className="block f2 uppercase">
              Work
            </Link>
          </li>

          <li className="footer__link transition-opacity f2 uppercase">
            <Link to="/about" className="block f2 uppercase">
              About
            </Link>
          </li>

          <li className="footer__link transition-opacity f2 uppercase">
            <Link to="/artists" className="block f2 uppercase">
              Index
            </Link>
          </li>
        </ul>

        <Link to="/">
          <AndTomorrow
            className="h-24 md:h-16 sm:h-12 mb-2 sm:mb-1"
            color={`${clipped ? `#1A191A` : `#F8F6F1`}`}
          />
        </Link>
      </div>
    </div>
  </footer>
);

export default Footer;
